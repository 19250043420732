import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  ButtonsContainer,
  GraphUnitButtons,
  MainBalance,
  PricesGraph,
  RangeButtons,
  SeoHeaders,
  VariationBalance,
} from 'shared/components'
import { ASSETS_PRICES_QUERY } from 'shared/queries'
import { getPriceGraphData } from 'shared/services'

import type { RouteComponentProps } from '@reach/router'
import type { AssetsPricesData, AssetsPricesVars, LongRange } from 'shared/queries'

const graphHeight = 280

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Portfolio = (props: RouteComponentProps) => {
  const [range, setRange] = React.useState<LongRange>('24h')
  const [graphUnit, setGraphUnit] = React.useState<string>('USD')

  const { loading, data } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: graphUnit,
        symbols: ['USDT'],
        range,
      },
    })

  const graphData = getPriceGraphData(data?.assetsPrices || [], 'USDT')

  return (
    <React.Fragment>
      <SeoHeaders title='Portafolio' />
      <Grid
        container
        spacing={3}
      >
        <MainBalance
          loading={loading}
          currency={graphUnit}
          amount={0}
        />
        <VariationBalance
          loading={loading}
          currency={graphUnit}
          range={range}
          amount={0}
        />
        <ButtonsContainer>
          <GraphUnitButtons
            disabled={loading}
            keys={['USD', 'CLP']}
            graphUnit={graphUnit}
            setGraphUnit={setGraphUnit}
            containerProps={{ justifyContent: 'flex-end' }}
          />
          <RangeButtons
            disabled={loading}
            keys={['24h', '7d']}
            range={range}
            setRange={setRange}
            containerProps={{ justifyContent: 'flex-end' }}
          />
        </ButtonsContainer>
        <AppContainer sx={{ height: graphHeight, py: 1 }}>
          <PricesGraph
            currency={graphUnit}
            isStableCoin={true}
            data={graphData.points}
            ath={graphData.ath}
            atl={graphData.atl}
          />
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default Portfolio
