import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, InputAdornment, Stack, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import * as Yup from 'yup'

import {
  ButtonContainer,
  Currency,
  ErrorDisplay,
  NotificationConfirmation,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import { NOTIFY_USER_REQUEST_MUTATION } from 'shared/queries'
import { setFormError, translateError } from 'shared/services'

import type { FormikProps } from 'formik'
import type { NotifyUserRequestData, NotifyUserRequestVars } from 'shared/queries'

type FormValues = {
  amount: number
  useMaxAmount: boolean
}

const initialValues: FormValues = {
  amount: 0,
  useMaxAmount: false,
}

const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero'),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
  })
)

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
}: FormikProps<FormValues>) => (
  <Form>
    <Stack
      spacing={2}
      marginTop={2}
    >
      <CurrencyField
        name='amount'
        label='Monto a retirar'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <small>US</small>&nbsp;$
            </InputAdornment>
          ),
        }}
        digits={2}
        positive
      />
      <Field
        required
        component={CheckboxWithLabel}
        disabled={!values.amount}
        type='checkbox'
        name='useMaxAmount'
        Label={{
          sx: { mt: -1, mb: 1 },
          label: (
            <React.Fragment>
              Usar el máximo disponible (aprox.&nbsp;
              <Currency
                currency='USD'
                value={0}
              />)
            </React.Fragment>
          ),
        }}
      />
      <Typography
        mt={2}
        variant='body2'
        textAlign='right'
      >
        Aproximadamente&nbsp;
        <Currency
          currency='CLP'
          value={values.amount * 920}
        />
      </Typography>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonContainer>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </Stack>
  </Form>
)

export const WithdrawBankForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'WITHDRAW_BANK',
        content: ['US$', values.amount.toString()],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  return showConfirmation ? (
    <NotificationConfirmation message={message} />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        Formulario de retiro bancario
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm {...props} />
        )}
      </Formik>
    </React.Fragment>
  )
}
